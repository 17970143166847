
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification\ 
for details on configuring this project to bundle and minify static web assets. */
body {
    padding-top: 50px;
    padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* QR code generator */
#qrCode {
    margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    /* Hide captions */
    .carousel-caption {
        display: none;
    }
}

/* para ajustar el color de los botones success*/
.btn.btn-success {
    color: #ffffff;
    background-color: var(--color-principal) !important;
    border-color: var(--color-principal) !important;
}

/* seteo el color de gizmedic para acceder desde cualquier page */
:root {
    --color-principal: #f38120;
    --color-principal-inactive: #e9a56a;
    --color-principal-active: #6b390d;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary.focus:not(.btn-text) {
    color: #FFFFFF !important;
    background-color: #d8eaef !important;
    border-color: #d8eaef !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 10px !important;
}

#kt_content {
    padding-bottom: 0px !important;
    padding-top: 25px !important;
}

.aside, .aside-menu{
    color: #FFF !important;
    background-color: #f38120 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
    color: #FFF !important;
}

span.svg-icon svg, span.menu-icon svg{
    fill: #FFF !important
}

.menu-text{
    color: #FFF !important
}

a.menu-link.active{
  background-color: #7c3d06 !important;
}

div.menu-submenu.show{
  display: block !important;
  overflow: hidden;
  max-height: 500px; 
  transition: max-height 0.2s ease-in !important;
}

div.menu-submenu.hide{
  display: block !important;
  max-height: 0px;
  overflow: hidden; 
  transition: max-height 0.2s ease-out !important;
}

.brand-dark .brand{
  background-color: white;
}